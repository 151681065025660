
import React, { useEffect, useState, useRef } from 'react';
import '../stili/App.css';
import '../stili/App01.css';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';

import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import { FileUpload, FileUploadErrorEvent, FileUploadUploadEvent, FileUploadHandlerEvent } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';


import AWS from 'aws-sdk';





//firebase 



import ProfiloUtente from '../obj/ProfiloUtente';
import datiGen from '../util/datiGen';

import { useNavigate, useLocation, Link } from "react-router-dom";
import restProfiloUtente from '../restcall/restProfiloUtente';
import { getAuth } from 'firebase/auth';
let deferredPrompt;


interface Props {

  refresh: () => void;
}



const MieiDati = (props:Props) => {
  const [putente, setPutente] = useState<ProfiloUtente>();


  const navigate = useNavigate();
  const location = useLocation();
  const toast = useRef<Toast>(null);
  const fileUploadReference = useRef<FileUpload>(null);
  const auth = getAuth();
  

  useEffect(() => {
    if (datiGen.getInstance().getUtenteCollegato())
    {
      let zz=  Object.assign(new ProfiloUtente(), datiGen.getInstance().getUtenteCollegato()); 
      zz.to_obj();
    setPutente(zz);
    }
  }, []);


  const showError = (testo:string) => {
    toast.current?.show({ severity: 'error', summary: 'Attenzione !!', detail: testo, life: 8000 });
  };


  const onUpload = (ev: FileUploadUploadEvent) => {
    toast.current?.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    console.log("File uploaded", ev);
  };
  const onError = (ev: FileUploadErrorEvent) => {
    toast.current?.show({ severity: 'error', summary: 'Error', detail: ev.xhr.statusText });
    console.log("File not uploaded", ev, ev.xhr.response, ev.xhr.statusText);
  };

  function uploadHandler(ev: FileUploadHandlerEvent): void {
  
    ev.files.forEach((f) => {
      uploadFile(f);
    });
    
  }
  const downloadfile = (chiave: string): string => {
    const S3_BUCKET = "avatar";

    // S3 Region
    const REGION = "r1-it.storage.cloud.it";

    // S3 Credentials
    AWS.config.update({

      accessKeyId: "weveering",
      secretAccessKey: "WeVee@78@",
    });
    const s3 = new AWS.S3({
      endpoint: "r1-it.storage.cloud.it",
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    let itmp = { ...putente };
    let kkey: string = "avatar/" + itmp._id + "/" + chiave;
    var params = { Bucket: S3_BUCKET, Key: kkey, Expires: 60 };
    var url = s3.getSignedUrl('getObject', params);
    console.log('The URL is', url); // expires in 60 seconds
    return url;
  }


  const s3DeleteObject = (chiave: string) => {
    const S3_BUCKET = "avatar";

    // S3 Region
    const REGION = "r1-it.storage.cloud.it";

    // S3 Credentials
    AWS.config.update({

      accessKeyId: "weveering",
      secretAccessKey: "WeVee@78@",
    });
    const s3 = new AWS.S3({
      endpoint: "r1-it.storage.cloud.it",
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    return new Promise((resolve, reject) => {
      let itmp = { ...putente };
      let kkey: string = "avatar/" + itmp._id + "/" + chiave;
      var params = { Bucket: S3_BUCKET, Key: kkey };
  
      s3.deleteObject( params,(err: any, data: any) => {
        if (err) {
          reject(err);
          console.log("Error deleting file",err);
        } else {
          resolve(data);
          console.log("File eliminato",data);
        }
      });
    });
  };


  // Function to upload file to s3
  const uploadFile = async (file: File) => {
    // S3 Bucket Name
    const S3_BUCKET = "avatar";

    // S3 Region
    const REGION = "r1-it.storage.cloud.it";

    // S3 Credentials
    AWS.config.update({

      accessKeyId: "weveering",
      secretAccessKey: "WeVee@78@",
    });
    const s3 = new AWS.S3({
      endpoint: "r1-it.storage.cloud.it",
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: "avatar/" + putente?._id + "/" + file.name,
      Body: file,
    };

    // Uploading file to s3

    s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + ((evt.loaded * 100) / evt.total) + "%"
        );

      }).promise()
      .then((upload: any) => {
        console.log("upload Terminato");

        let itmp:ProfiloUtente = Object.assign(new ProfiloUtente(), { ...putente });
        itmp.photoURL= file.name;
        console.log("upload obj", 444, itmp);
        setPutente(itmp);
      //  props.updateImmobile(itmp,true);
      //  setImmo({ ...itmp, immagini: itmp.immagini });
        
      restProfiloUtente.getInstance().modifica(itmp);
       

        fileUploadReference.current?.clear();
      }).catch((error: any) => {
        console.error(error);
        alert("Error uploading file: " + error.message); // Inform user about the error
      });

  };


  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'bottoneSceltaAzione-p-button' };
  const chooseOptions = { icon: 'pi pi-fw pi-user', iconOnly: false, className: 'bottoneUpload-p-button butt-avatar' };


const getAvatar=():string|undefined=>
{
let av=putente?.getAvatarUrl();
if (!av)
  av='';
else if(!av.startsWith('http'))
{
  av=downloadfile(putente?.photoURL!);
}



return av;
}


  return (

    <div className='RiepExtContainer tabresp '  >
    <Toast ref={toast} position='center' />


      <p className='riepTitolo' style={{ borderBottom: '0.5px solid #46c0b5' , marginBottom:'25px'}}>
        I Miei Dati
      </p>


      <div style={{}} className='riepdiv'>
        {putente &&
        <div className="centra">

        
        <Avatar image={getAvatar()} icon="pi pi-user" size="xlarge" shape="circle" 
        onClick={()=>{console.log("click avatar");}}
        />
        
            <FileUpload name="demo[]" auto url="/api/upload" accept="image/*" className='cambia-avatar'  maxFileSize={30000000} 
               onUpload={onUpload} onError={onError}  mode="basic" 
              uploadHandler={uploadHandler} ref={fileUploadReference} 
              chooseLabel='Cambia foto' 
              uploadOptions={uploadOptions} chooseOptions={chooseOptions}
            />
          

        
         <div className='' style={{ display: 'flex', flexDirection: 'column'}}>
                <InputText className='testoUpper wee-p-inputtext' value={putente.cognome} placeholder='Cognome' required onChange={(e) => {setPutente({ ...putente, cognome: e.target.value });}} />
                <InputText className='testoUpper wee-p-inputtext' value={putente.nome} placeholder='Nome' required onChange={(e) => { setPutente({ ...putente, nome: e.target.value }); }} />
                <InputText className='testoUpper wee-p-inputtext' value={putente.nickname} placeholder='Nickname' required onChange={(e) => { setPutente({ ...putente, nickname: e.target.value }); }} />
                <InputText className='testoLower wee-p-inputtext' value={putente.email} placeholder='E-mail' onChange={(e) => { setPutente({ ...putente, email: e.target.value }); }} />
                <span style={{ color: 'white' }}>+39</span><InputText className='wee-p-inputtext' value={putente.telefono} keyfilter="int" placeholder='Telefono' onChange={(e) => { setPutente({ ...putente, telefono: e.target.value }); }} />
         </div>
         <Button rounded className="bottoneSceltaAzione-p-button" style={{ maxWidth: '300px', marginBottom:'100px'}} size='large' onClick={() => { let e: restProfiloUtente = restProfiloUtente.getInstance()!;
         let pu={...putente};
         pu.authuser=getAuth().currentUser!;
         pu.displayName=pu.cognome+" "+pu.nome;
         e.modifica(pu).then((zz)=>
         {
        if (zz.esito && zz.esito=='KO')
        {
          showError(zz.des);
        }
          else
                  setPutente(pu);
         setTimeout(() => { props.refresh()  }, 2000); })} 
        }
         >
                <span className='bottoneSceltaAzione-label' >Salva</span>
              </Button>
         </div>
	
            }
</div>
	 <p className='riepTitolo' style={{ borderBottom: '0.5px solid #46c0b5' , marginBottom:'25px'}}>
        
      </p>
<Toast ref={toast}></Toast>
</div>
      );
}



      export default MieiDati;
